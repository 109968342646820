<template>
  <component :is="tag" :class="computedClass">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  level: {
    type: Number,
    required: true,
    validator: (value: number) => value >= 1 && value <= 6,
  },
  uppercase: {
    type: Boolean,
    default: false,
  },
});

const tag = computed(() => {
  return `h${props.level}`;
});

const computedClass = computed(() => {
  let sizeClass;

  switch (props.level) {
    case 1:
      sizeClass = 'text-2xl md:text-3xl font-bold'; // 30px
      break;
    case 2:
      sizeClass = 'text-2xl font-medium tracking-tight md:text-2xl lg:text-3xl'; // 24px
      break;
    case 3:
      sizeClass = 'text-md font-semibold'; // 18px
      break;
    default:
      sizeClass = 'text-base';
  }

  return `${sizeClass} ${props.uppercase ? 'uppercase' : ''}`;
});
</script>
